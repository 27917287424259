.off-canvas {
	z-index: 50000;
	width: 100%;
	transform: translateX(-100%);
	background-color: get-color('black');

	&.is-open {
		transform: translateX(0);
	}

	&__inner {
		padding: 20px;

		@include breakpoint(large) {
			padding: 35px 53px;
		}
	}

	&__header {
		position: relative;
		margin-bottom: 60px;
	}

	&__close {
		position: absolute;
		top: 0;
		left: 0;
		width: 21px;
		height: 21px;
		background: url('img/close-thin-white.svg') no-repeat;
		background-size: 21px 21px;
		cursor: pointer;
		text-indent: -12345px;

		&:focus,
		&:active {
			outline: none;
		}
	}

	&__logo {
		width: 151px;
		margin: 0 auto;
	}

	&__nav-primary {
		margin-bottom: 65px;
		margin-top: 50px;

		a {
			transition: color 0.3s ease-in-out;
			color: get-color('white');

			&:hover,
			&:active {
				color: get-color('red');
			}
		}

		> ul {
			margin: 0;
			list-style-type: none;

			> li {
				margin-bottom: 24px;

				> a {
					@include h2;
					font-size: 25px;
					color: get-color('white');
				}

				&.open > a {
					color: get-color('red');
				}

				> ul {
					margin: 10px 0 30px 35px;
					list-style-type: none;
					display: none;

					@include breakpoint(small only) {
						margin-left: 0;
					}

					> li {
						margin-bottom: 0;

						@include breakpoint(small only) {
							line-height: 1;
							margin-bottom: 20px;
						}

						> a {
							@include h2;
							font-size: 15px;
							color: get-color('white');
						}
					}
				}
			}
		}
	}

	&__nav-header {
		display: none;

		@media screen and (max-width: $offcanvas-breakpoint) {
			display: block;
		}

		ul {
			margin: 0 auto;
			list-style-type: none;
		}

		a {
			@include subheading();
		}
	}

	.footer__newsletter {
		max-width: none;
	}
}
