.offers {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto 120px;
	
	&__grid {
		@include breakpoint(small only) {
			padding: 0 20px;
		}
	}
	
	&__item {
		margin-bottom: 100px;
		
		@include breakpoint(small only) {
			margin-bottom: 60px;
		}
		
		&-content {
			background-color: get-color('light-grey');
			padding: 40px 20px;
			
			@include breakpoint(large) {
				padding: 40px 40px;
			}
			
			h2 {
				@include h2;
				
				a {
					color: inherit;
				}
			}
			
			h3 {
				@include price;
			}
			
			p {
				@include body-copy();
			}
			
			ul {
				@include bullet-list();
			}
		}
		
		&-description {
			@include breakpoint(medium) {
				padding-right: 40px;
			}
		}
		
		&-price {
			@include breakpoint(small only) {
				padding-bottom: 15px;
			}
			
			@include breakpoint(medium) {
				padding-top: 10px;
				text-align: right;
			}
		}
		
		.multiple-buttons {
			@include breakpoint(medium) {
				text-align: right;
				display: flex;
				flex-direction: column;
				padding-left: 20px;
				
				.button {
					display: block;
					margin: 20px 0 0;
					
					&:first-child {
						margin-top: auto;
					}
				}
			}
		}
		
		&-image {
			position: relative;
			min-height: 223px;
			
			@include breakpoint(medium) {
				a,
				picture {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
				
				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	
	
}