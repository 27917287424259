.slider {
  position: relative;
  text-align: center;

  .slider__inner {
    width: 100vw;
    height: 75vh;
  }

  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div {
    height: 100%;
  }

  &--full-height {
    .slider__inner {
      width: 100vw;
      height: 100vh;

      @include breakpoint(small only) {
        height: 66vh;
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    @include breakpoint(small only) {
      height: 66vh;
    }

    picture {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    iframe,
    video {
      position: absolute;
    }
  }

  &__caption {
    position: absolute;
    top: 50%;
    left: 40px;
    right: 40px;
    color: #fff;
    z-index: 1;
    max-width: 720px;
    width: auto;
    margin: auto;

    h2 {
      @include slider-caption();
      color: get-color("white");
      margin-bottom: 24px;

      @include breakpoint(small only) {
        margin-bottom: 10px;
      }
    }

    p {
      @include subheading();
      font-family: $primary-font;
      color: get-color("white");
      font-size: 12px;
      line-height: 19px;
      letter-spacing: 1px;
    }

    .button {
      margin-top: 14px;

      @include breakpoint(small only) {
        margin-top: 5px;
      }
    }
  }

  &__scroll {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    z-index: 1000;

    @media screen and (max-width: $offcanvas-breakpoint) {
      bottom: 60px;
    }

    button {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      text-indent: -123456px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      background: url("img/arrow-thin-down-white.svg") no-repeat;
      background-size: 100% 100%;

      &:hover,
      &:active {
        transform: translateY(5px);
      }
    }
  }
}
