.header {
	position: fixed;
	z-index: 2000;
	top: 0;
	left: 0;
	width: 100vw;
	box-shadow: 0 0 5px 5px rgba(#000, 0.2);
	background-color: $body-background;
	transition: background-color 0.3s ease-in-out;
	height: 73px;

	@include breakpoint(small only) {
		box-shadow: 0 0 20px rgba(#000, 0.3);
	}

	&__inner {
		position: relative;
		width: 100%;
	}

	&__open-off-canvas {
		position: absolute;
		top: 28px;
		left: 53px;
		width: 31px;
		height: 16px;
		text-indent: -123456px;
		background: url('img/burger-black.svg') no-repeat;
		background-size: 31px 16px;
		cursor: pointer;
		border: 0 !important;
		background-color: transparent !important;

		@media screen and (max-width: $offcanvas-breakpoint) {
			top: 24px;
			left: 20px;
		}
	}

	&__open-booking {
		position: absolute;
		top: 21px;
		right: 53px;
		margin: 0;

		@media screen and (max-width: $offcanvas-breakpoint) {
			position: fixed;
			top: auto;
			bottom: 1px;
			left: 0;
			width: 100%;
			background-color: get-color('grey');
			color: #fff;
			border: 1px solid #fff;
		}

		a {
			color: #fff;
		}
	}

	&__mobile-call {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 28px;
		height: 28px;
		text-indent: -12345px;
		margin: 0;
		padding: 0;
		border: 0;
		background: none transparent;
		display: none;

		@media screen and (max-width: $offcanvas-breakpoint) {
			display: block;
		}

		img,
		svg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			path {
				stroke: get-color('black');
			}
		}
	}

	.button.header__mobile-call {
		border: none;
		background-color: transparent;
	}

	&__logo {
		width: 80%;
		max-width: 250px;
		margin: 0 auto;
		padding-top: 23px;

		@include breakpoint(small only) {
			max-width: 152px;
		}

		&--transparent,
		&--compact {
			display: none;
		}

		img {
			width: 100%;
		}
	}

	&__top-nav {
		position: absolute;
		top: 21px;

		&--left {
			left: 176px;
		}

		&--right {
			right: 180px;
		}

		@media screen and (max-width: $offcanvas-breakpoint) {
			display: none;
		}

		ul {
			margin: 0;
			list-style-type: none;
		}

		li {
			display: inline;
			margin-left: 10px;
			margin-right: 19px;

			&:first-child {
				margin-left: 0;
			}

			a {
				position: relative;
				@include smallest();
				transition: all 0.3s ease-in-out;
				font-family: $primary-font;
				letter-spacing: 1px;

				&::after {
					content: '';
					position: absolute;
					bottom: -2px;
					left: 0;
					width: 0;
					border-bottom: 1px solid get-color('white');
					transition: width 0.3s ease-in-out;
				}

				&:hover,
				&:active {
					color: get-color('red');

					&::after {
						width: 30px;
						border-color: get-color('red');
					}
				}
			}
		}
	}

	&__lang {
		position: absolute;
		top: 21px;
		left: 114px;
		padding-top: 1px;

	@media screen and (max-width: $offcanvas-breakpoint) {
		right: initial;
		left: 66px;
		top: 17px;

		&.burger-offset {
			top: 55px;
			padding-top: 0px;
		}
	}

	button {
		position: relative;
		@include smallest();
		font-family: $primary-font;
		cursor: pointer;
		padding-right: 15px;
		color: get-color('black');



		&::before {
			content: '';
			position: absolute;
			top: -2px;
			right: -3px;
			width: 12px;
			height: 14px;
			background-image: url('img/lang-dropdown-black.svg');
			background-repeat: no-repeat;
			background-position: center;
			transition: background-image 0.3s ease-in-out;


			@include breakpoint(small only) {
				display: none;
			}
		}

		&:hover::before {
			background-image: url('img/lang-dropdown-red.svg');
		}

		&.open::before {
			background: url('img/lang-dropdown-red.svg') no-repeat right center;
		}
	}

	ul {
		position: absolute;
		z-index: 20000;
		top: 15px;
		right: 0;
		padding: 5px 15px;
		border: none;
		background: #fff;
		margin: 0;
		list-style-type: none;
		display: none;
		background-color: transparent;
	}

	a {
		@include smallest();
		color: get-color('black');
		transition: color 0.3s ease-in-out;
		font-family: $primary-font;
	}
}







	//////////////////////
	// Styles that are applied to only the transparent header
	//////////////////////
	&--transparent {
		background-color: transparent;
		background: linear-gradient(to bottom, rgba(0,0,0,0.48) 0%,rgba(0,0,0,0) 100%);
		box-shadow: none;
		padding-bottom: 40px;

		.header {
			&__open-off-canvas {
				background-image: url('img/burger-white.svg');
				top: 38px;
			}

			&__open-booking {
				@media screen and (min-width: $offcanvas-breakpoint) {
					top: 31px;
				}
			}

			&__logo {
				padding-top: 31px;

				&--transparent {
					display: block;
					max-width: 162px;

					@include breakpoint(small only) {
						max-width: 76px;
					}
				}

				&--normal,
				&--compact {
					display: none;
				}
			}

			&__top-nav {
				top: 31px;

				a {
					color: #fff;
				}
			}

			&__lang {
				top: 31px;

				button {
					color: get-color('white');
					font-family: $primary-font;
				}

				a {
					color: get-color('white');
					font-family: $primary-font;
				}

				button {
					&::before {
						background: url('img/lang-dropdown.svg') no-repeat right center;

						@include breakpoint(small only) {
							display: none;
						}
					}

					&:hover::before {
						background: url('img/lang-dropdown-red.svg') no-repeat right center;
					}

					&.open::before {
						background: url('img/lang-dropdown-red.svg') no-repeat right center;
					}
				}
			}

			&__mobile-call svg path {
				stroke: #fff;
			}
		}
	}
}
