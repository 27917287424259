@font-face {
  font-family: 'Micro Extended';
  src: url('fonts/Microextendflf-lgK0.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Micro Extended';
  src: url('fonts/MicroextendflfBold-nRg0.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Micro Extended';
  src: url('fonts/MicroextendflfBolditalic-1GaB.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'Micro Extended';
  src: url('fonts/MicroextendflfItalic-RpJ6.ttf') format('truetype');
  font-style: italic;
}
